import 'flowbite';
import { Livewire, Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';
import mask from '@alpinejs/mask'
import Sortable from 'sortablejs';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import { uniqueId } from 'lodash';
import QrScanner from 'qr-scanner';

import.meta.glob([
	'../../images/**',
]);

window.Chart = Chart;
window.Alpine = Alpine;
window.Sortable = Sortable;
window.uniqueId = uniqueId;
window.QrScanner = QrScanner;

Alpine.plugin(mask);

Livewire.start();
